import {useAnalytics} from "use-analytics"
import Sentry from "@gooditworks/monitoring/next"

import {trpc} from "datasources/trpc"

const useAuthozrizedAnalytics = () => {
  const {track, page, identify} = useAnalytics()

  trpc.user.me.useQuery(undefined, {
    onSuccess: user => {
      if (!user) return

      identify(user.id, user)
      Sentry.setUser({
        id: user.id,
        email: user.email || undefined,
        username: user.name || undefined
      })
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  })

  return {track, page}
}

export default useAuthozrizedAnalytics
