import {createContext, useContext, useEffect} from "react"

type PageProps = Record<string, unknown>

type MutateHandler<T> = (prevValue: T) => T

interface Context<T extends PageProps = PageProps> {
  pages: Record<string, T>
  update(action: (prev: T) => T): void
}

const PageStateContext = createContext<Context>({
  pages: {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  update() {}
})

const usePageState = <P extends PageProps>(name: string, staticProps: P): P => {
  const context = useContext(PageStateContext)
  const stateProps = context.pages[name] as P

  useEffect(() => {
    if (!stateProps) {
      context.update(prev => ({...prev, [name]: staticProps}))
    }
  })

  return stateProps || staticProps
}

const usePageStateMutate = <P extends PageProps>(name: string) => {
  const context = useContext(PageStateContext)

  const mutate = (handler: MutateHandler<P>) => context.update(prev => {
    const staticProps = prev[name] as P

    if (!staticProps) {
      return prev
    }

    const updatedProps = handler(staticProps)

    return {...prev, [name]: updatedProps}
  })

  return mutate
}

export {PageStateContext, usePageState, usePageStateMutate}
export type {PageProps}
