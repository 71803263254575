const clientEnv = {
  sentryEnv: process.env.NEXT_PUBLIC_SENTRY_ENV,
  sentryDsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  logdnaApp: process.env.NEXT_PUBLIC_LOGDNA_APP,
  logdnaKey: process.env.NEXT_PUBLIC_LOGDNA_KEY,
  telegramBotUsername: process.env.NEXT_PUBLIC_TELEGRAM_BOT_USERNAME,
  whatsAppBotNumber: process.env.NEXT_PUBLIC_WHATSAPP_BOT_NUMBER,
  whatsAppGreetingText: "Press ‘send’ button to link your YUNG account!",
  analyticsIdGoogle: process.env.NEXT_PUBLIC_ANALYTICS_ID_GOOGLE,
  analyticsIdYandex: process.env.NEXT_PUBLIC_ANALYTICS_ID_YANDEX
}

export default clientEnv
