import {useMemo, useState, FC} from "react"
import Head from "next/head"
import {AppProps} from "next/app"
import {SessionProvider} from "next-auth/react"
import {QueryClient, QueryClientProvider} from "@tanstack/react-query"
import {AnalyticsProvider} from "use-analytics"

import ErrorCapturer from "components/ErrorCapturer"
import {trpc, createReactClient} from "datasources/trpc"
import {PageStateContext} from "lib/pageState"
import analytics from "datasources/analytics"
import PageViewAnalytics from "components/PageViewAnalytics"

import "styles/fonts.css"
import "styles/swiper.css" // ! Важно чтобы эти стили шли перед globals.css
import "styles/globals.css"
import "styles/typography.css"
import {Session} from "next-auth"

const App: FC<AppProps<{session: Session | null}>> = ({Component, pageProps}) => {
  const [pagesState, setPagesState] = useState({})
  const pageStateContext = useMemo(
    () => ({pages: pagesState, update: setPagesState}),
    [pagesState]
  )

  const [queryClient] = useState(() => new QueryClient())
  const [trpcClient] = useState(() => createReactClient())

  return (
    <ErrorCapturer>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <title>Yung</title>
      </Head>
      <SessionProvider session={pageProps.session}>
        <PageStateContext.Provider value={pageStateContext}>
          <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
              <AnalyticsProvider instance={analytics}>
                <PageViewAnalytics>
                  <Component {...pageProps} />
                </PageViewAnalytics>
              </AnalyticsProvider>
            </QueryClientProvider>
          </trpc.Provider>
        </PageStateContext.Provider>
      </SessionProvider>
    </ErrorCapturer>
  )
}

export {reportWebVitals} from "next-axiom"
export default App
