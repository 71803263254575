import Analytics from "analytics"

import googleTagManager from "@analytics/google-tag-manager"
import yandexMetrica from "lib/analyticsMetricaPlugin.js"

import env from "env/client"

const getPlugins = () => {
  if (typeof window === "undefined") {
    return []
  }

  const plugins = []

  if (env.analyticsIdGoogle) {
    plugins.push(googleTagManager({containerId: env.analyticsIdGoogle}))
  }

  if (env.analyticsIdYandex) {
    plugins.push(yandexMetrica({ymId: env.analyticsIdYandex}))
  }

  return plugins
}

const analytics = Analytics({
  app: "yung",
  plugins: getPlugins()
})

export default analytics
