var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2e8ad819f11bf8861eea755233a7b6637dd68f9d"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import initMonitoring from "@gooditworks/monitoring"

import ConsoleTransport from "@gooditworks/monitoring/logger/transport/console"
import ConsoleCapturer from "@gooditworks/monitoring/logger/capturer/console"
import SentryNextCapturer from "@gooditworks/monitoring/logger/capturer/sentryNext"

import env from "./src/env/client"

const loggerTransports = [new ConsoleTransport()]
const exceptionCapturers = [new ConsoleCapturer()]

if (env.sentryDsn && typeof window !== "undefined") {
  const sentryCapturer = new SentryNextCapturer({
    dsn: env.sentryDsn,
    environment: env.sentryEnv,
    tracesSampleRate: 0.2
  })

  exceptionCapturers.push(sentryCapturer)
}

initMonitoring({
  logger: {
    loggerTransports,
    exceptionCapturers
  }
})
