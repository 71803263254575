import {FC, ReactElement, useEffect} from "react"
import {useRouter} from "next/router"

import useAuthozrizedAnalytics from "lib/hooks/useAuthorizedAnalytics"

interface Props {
  children: ReactElement
}

const PageViewAnalytics: FC<Props> = ({children}) => {
  const router = useRouter()
  const {page} = useAuthozrizedAnalytics()

  const onPageChange = () => page()

  useEffect(() => {
    onPageChange()
    router.events.on("routeChangeComplete", onPageChange)

    return () => router.events.off("routeChangeComplete", onPageChange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return children
}

export default PageViewAnalytics
