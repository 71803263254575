import {createTRPCReact} from "@trpc/react-query"
import {httpBatchLink, createTRPCProxyClient} from "@trpc/client"
import superjson from "superjson"

import type {AppRouter} from "server/router"

const baseUrl = () => {
  if (typeof window !== "undefined") {
    return ""
  }

  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`
  }

  return "http://localhost:3000"
}

const transformer = superjson
const links = [httpBatchLink({url: `${baseUrl()}/api/trpc`})]

export const trpc = createTRPCReact<AppRouter>()

export const client = createTRPCProxyClient<AppRouter>({transformer, links})

export const createReactClient = () => trpc.createClient({transformer, links})
